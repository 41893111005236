.container-about {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0px;
  margin: auto;
  max-width: 1200px;
  gap: 70px;
  justify-content: space-evenly;
  padding-block: 60px;
  padding-inline: 30px;
}

.column-about {
  -ms-flex: 55%;
  flex: 55%;
  max-width: 55%;
  padding: 0px;
}

.column-img {
  -ms-flex: 45%;
  flex: 45%;
  max-width: 45%;
  padding: 0px;
}

.row-1 {
  padding-block: 30px;
  gap: 20px;
}

.row-2 {
  display: grid;
  gap: 12px;
}

.row-3 {
  display: grid;
  gap: 8px;
  padding-block: 30px;
}

.row-4 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-bottom: 1px solid black;
  inline-size: fit-content;
  height: 24px;
  cursor: pointer;
}

.row-4:hover {
  opacity: 0.5;
}

.image {
  width: 100%;
}

@media (max-width: 1000px) {
  .container-about {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    padding: 0px;
    margin: auto;
    max-width: unset;
    gap: 30px;
    justify-content: space-evenly;
    padding-block: unset;
    padding-inline: unset;
  }

  .column-about {
    -ms-flex: 45%;
    flex: 45%;
    max-width: 100%;
    padding: 0px;
    padding-inline: 30px;
  }

  .column-img {
    -ms-flex: 45%;
    flex: 45%;
    max-width: 100%;
    padding: 0px;
  }

  .row-1 {
    padding-block: 30px;
    gap: 20px;
  }

  .row-2 {
    display: grid;
    gap: 12px;
  }

  .row-3 {
    display: grid;
    gap: 8px;
    padding-block: 30px;
  }

  .row-4 {
    display: flex;
    flex-direction: row;
    gap: 8px;
    border-bottom: 1px solid black;
    inline-size: fit-content;
    height: 24px;
    cursor: pointer;
  }

  .row-4:hover {
    opacity: 0.5;
  }

  .image {
    width: 100%;
  }
}
