.main-container {
  padding-bottom: 60px;
  margin: auto;
  max-width: 1200px;
  padding-inline: 30px;
}
.container-title {
  display: flex;
  flex-direction: row;
}

.container-resume {
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0px;
  margin: auto;
  max-width: 1200px;
  gap: 30px;
  justify-content: space-evenly;
  padding-block: 60px;
  border-bottom: 1px solid #f5f5f5;
}

.container-resume-title {
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0px;
  margin: auto;
  max-width: 1200px;
  gap: 30px;
  padding-top: 60px;
  text-align: left;
}

.container-resume-inner {
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0px;
  margin: auto;
  max-width: 1200px;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 60px;
  text-align: left;
  border-bottom: 1px solid #f5f5f5;
}

.column-resume {
  -ms-flex: 65%;
  flex: 65%;
  width: 500px;
  padding: 0px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.bullets {
  -ms-flex: 65%;
  flex: 65%;
  width: 500px;
  padding: 0px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.column-resume-link {
  width: 500px;
  display: flex;
  flex-direction: row-reverse;
}

.bottom-link {
  padding-top: 60px;
  display: flex;
  justify-content: middle;
}

.job-description {
  display: grid;
}

.bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.skills {
  display: grid;
  gap: 4px;
}

.job-title {
  padding-bottom: 12px;
  color: #55615c;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
}

.company {
  font-family: 'Outfit' sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  padding-bottom: 8px;
}

.date {
  font-family: 'Outfit' sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #999999;
}

.text-2 {
  color: grey;
}

@media (max-width: 1000px) {
  .main-container {
    padding-bottom: 60px;
    margin: unset;
    max-width: unset;
    padding-inline: 30px;
  }
  .container-title {
    display: flex;
    flex-direction: row;
  }

  .container-resume {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0px;
    margin: unset;
    max-width: unset;
    gap: 20px;
    justify-content: space-evenly;
    padding-block: 30px;
    border-bottom: 1px solid #f5f5f5;
  }

  .container-resume-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0px;
    margin: unset;
    max-width: unset;
    gap: 20px;
    padding-top: 30px;
    text-align: left;
  }

  .container-resume-title-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0px;
    margin: unset;
    max-width: unset;
    gap: 20px;
    padding-top: 0px;
    text-align: left;
  }

  .container-resume-inner {
    display: flex;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 0px;
    margin: auto;
    max-width: unset;
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: left;
    border-bottom: 1px solid #f5f5f5;
  }

  .column-resume {
    flex: unset;
    width: unset;
    padding: 0px;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  .bullets {
    flex: unset;
    width: unset;
    padding: 0px;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  .column-resume-link {
    width: unset;
    display: flex;
    flex-direction: row;
  }

  .job-description {
    display: grid;
  }

  .bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .skills {
    display: grid;
    gap: 4px;
  }

  .job-title {
    padding-bottom: 12px;
    color: #55615c;
    font-weight: 400;
    font-size: 26px;
    line-height: 34px;
  }

  .company {
    font-family: 'Outfit' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
    padding-bottom: 8px;
  }

  .date {
    font-family: 'Outfit' sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #999999;
  }

  .text-2 {
    color: grey;
  }

  .bottom-link {
    padding-top: 30px;
  }
}
