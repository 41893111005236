.header {
  background-color: #ffffff;
  display: flex;
  position: sticky;
  max-width: 100% !important;
  justify-content: space-between;
  flex-direction: row;
  padding-inline: 30px;
  padding-block: 16px;
  align-items: center;
  top: 0;
}

.header-icon {
  align-items: flex-start;
}

.header-tabs {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 30px;
  height: 24px;
  justify-content: flex-end;
  position: relative;
}

.tabs-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
}

.tab-text {
  font-family: 'outfit', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.header-menu {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  /* width: 200px; */
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
}

.header-menu:hover {
  color: rgba(0, 0, 0, 0.5);
}

.hover-links {
  color: #384742;
  font-family: 'Outfit', Helvetica;
  font-size: 16px;
  letter-spacing: 0.52px;
  line-height: 20px;
  text-decoration: none;
}

.hover-links:active {
  text-decoration: none;
}

.link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}

a:link {
  color: black;
}

a:hover {
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 800px) {
  .header {
    padding-inline: 20px;
    padding-block: 16px;
  }
}
