.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 60px 40px;
}

.column {
  -ms-flex: 50%;
  flex: 45%;
  max-width: 50%;
  padding: 30px 30px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.column.three {
  -ms-flex: 100%;
  flex: 100%;
  max-width: 29% !important;
  padding: 0 30px;
}

.column.full {
  -ms-flex: 100%;
  flex: 100%;
  max-width: 100%;
  padding: 0 30px;
}

.hide {
  visibility: hidden;
}

.text-section {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.visit-website {
  float: right;
  cursor: pointer;
}

.visit-website:hover {
  color: rgba(0, 0, 0, 0.5);
}
