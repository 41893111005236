* {
  padding: 0px;
  margin: 0px;
  border: none;
  outline: none;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Outfit', sans-serif;
}

/* FONTS */
.title-1 {
  font-family: 'Outfit', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  color: #000000;
}

.title-2 {
  font-family: 'Outfit', sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 40px;
  color: #000000;
}

.title-3 {
  font-family: 'Outfit', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: #000000;
}

.title-4 {
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #000000;
}

.text-1 {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #000000;
}

.text-2 {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(56, 71, 66, 0.7);
}

.text-3 {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  color: #000000;
}

a:link {
  /* text-decoration: underline; */
  text-underline-offset: 3px;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  color: rgba(0, 0, 0, 0.5);
}

a:active {
  text-decoration: underline;
  color: black;
}
