.container-homepage {
  width: 100%;
}

.wrapper-homepage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  margin-inline: 30px;
  padding-bottom: 30px;
}

.image-container {
  position: relative;
  width: 100%;
}

.image-default {
  display: inline;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  z-index: 1;
}

.image-default:hover {
  display: inline;
  width: 100%;
  transition:
    transform 0.5s,
    filter 1.5s ease-in-out;
}

.image-instance {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition:
    transform 0.5s,
    filter 1.5s ease-in-out;
}

.homepage-description {
  display: grid;
  text-align: center;
}

.image-description {
  display: grid;
  gap: 12px;
}

@media (max-width: 800px) {
  .wrapper-homepage {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    grid-auto-rows: minmax(100px, auto);
    margin-inline: 16px;
  }

  .homepage-description {
    display: grid;
    text-align: center;
  }
}
