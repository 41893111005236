.link {
  text-decoration: none;
}

.link:active {
  text-decoration: none;
}

.link:hover {
  color: rgba(0, 0, 0, 0.5);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  list-style-type: none !important;
  position: absolute;
  background-color: white;
  right: -60px;
  padding: 20px;
}

li {
  padding: 14px;
  transition: all linear 0.3s;
}

ul:hover {
  list-style-type: none;
  color: rgba(0, 0, 0, 0.5);
}

li:hover {
  cursor: pointer;
  color: #fff;
  color: rgba(0, 0, 0, 0.5);
}

ul:active {
  text-decoration: none;
}
