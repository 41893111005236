.footer {
  align-items: center;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 60px 20px;
  position: relative;
}

.footer .TSB {
  height: 62.85px !important;
  position: relative !important;
  width: 80px !important;
}

.footer .pages {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
}

.footer .text-wrapper {
  color: #000000;
  font-family: var(--portfolio-site-text-2-MEDIUM-font-family);
  font-size: var(--portfolio-site-text-2-MEDIUM-font-size);
  font-style: var(--portfolio-site-text-2-MEDIUM-font-style);
  font-weight: var(--portfolio-site-text-2-MEDIUM-font-weight);
  letter-spacing: var(--portfolio-site-text-2-MEDIUM-letter-spacing);
  line-height: var(--portfolio-site-text-2-MEDIUM-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.footer .social-icons {
  flex: 0 0 auto;
  position: relative;
}

.footer .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.footer .div-wrapper:hover {
  color: rgba(0, 0, 0, 0.5);
}

.footer .p {
  color: #000000;
  font-family: 'Outfit', Helvetica;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.52px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.footer-instance {
  flex: 0 0 auto !important;
  margin-left: -30px !important;
  margin-right: -30px !important;
  width: 1440px !important;
}
