.container-portfolio {
  display: grid;
  gap: 60px;
}

.wrapper-portfolio {
  display: flex;
  padding-inline: 30px;
  grid-column-gap: 100px;
}

.wrapper-portfolio-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  margin-inline: 30px;
  padding-bottom: 30px;
}

.column-portfolio {
  display: grid;
  gap: 4px;
}

.column-portfolio2 {
  display: grid;
  gap: 28px;
}

.row-portfolio {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

/* david */
.image {
  height: auto;
}

.sodastream-image-1 {
  grid-column: 1/2;
  grid-row: 1;
}

.sodastream-image-2 {
  grid-column: 2/2;
  grid-row: 1;
}

.sodastream-image-3 {
  grid-column: 2/2;
  grid-row: 2;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 4px;
}

.text-section {
  width: 40%;
  gap: 8px;
}

.column-image {
  flex: 45% 1;
  max-width: 100%;
  padding: 0px;
}

.banner-image {
  width: 100%;
}

.sodastream-column {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media screen and (max-width: 800px) {
  .container-portfolio {
    display: grid;
    gap: 30px;
  }

  .wrapper-portfolio {
    display: flex;
    flex-direction: column;
    padding-inline: 20px;
    grid-column-gap: 100px;
    gap: 20px;
  }

  .wrapper-portfolio-images {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    grid-auto-rows: minmax(0px, auto);
    margin-inline: 16px;
    justify-items: center;
  }

  .column-portfolio {
    display: grid;
    gap: 4px;
  }

  .column-portfolio2 {
    display: grid;
    gap: 20px;
  }

  .row-portfolio {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .text-section {
    width: 80%;
    gap: 8px;
  }

  .column-image {
    flex: 45% 1;
    max-width: 100%;
    padding: 0px;
  }

  .banner-image {
    width: 100%;
  }

  .sodastream-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
