.container-portfolio {
  display: grid;
  gap: 60px;
  width: 100%;
}

.wrapper-portfolio {
  display: flex;
  padding-inline: 30px;
  grid-column-gap: 100px;
  justify-content: space-around;
}

.wrapper-portfolio-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  margin-inline: 30px;
  padding-bottom: 30px;
}

.wrapper-portfolio-images-myka {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  margin-inline: 30px;
  padding-bottom: 30px;
}
.column-portfolio {
  display: grid;
  gap: 4px;
}

.column-portfolio2 {
  display: grid;
  gap: 28px;
}

.row-portfolio {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

/* david */
.image {
  height: auto;
  width: 100%;
}

.sodastream-image-1 {
  grid-column: 1/2;
  grid-row: 1;
}

.sodastream-image-2 {
  grid-column: 2/2;
  grid-row: 1;
}

.sodastream-image-3 {
  grid-column: 2/2;
  grid-row: 2;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 4px;
}

.text-section {
  width: 40%;
  gap: 8px;
}

.column-image {
  flex: 45% 1;
  max-width: 100%;
  padding: 0px;
}

.view-website {
  display: flex;
  gap: 100px;
}

.visit-website {
  display: flex;
  justify-content: flex-end;
}

.section-with-link {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.banner-image {
  background-image: url('//src/assets/myka-banner-dt.jpg');
  width: 100%;
}

@media screen and (max-width: 800px) {
  .container-portfolio {
    display: grid;
    gap: 30px;
  }
  .wrapper-portfolio {
    display: flex;
    flex-direction: column;
    padding-inline: 20px;
    grid-column-gap: 100px;
    gap: 20px;
  }

  .wrapper-portfolio-images {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    margin-inline: 20px;
    justify-items: center;
  }

  .wrapper-portfolio-images-myka {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    margin-inline: 20px;
    padding-bottom: 20px;
  }
  .column-portfolio {
    display: grid;
    gap: 4px;
  }

  .column-portfolio2 {
    display: grid;
    gap: 30px;
  }

  .row-portfolio {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .text-section {
    width: 100%;
    gap: 8px;
  }

  .column-image {
    flex: 45% 1;
    max-width: 100%;
    padding: 0px;
  }

  .view-website {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .visit-website {
    display: flex;
    justify-content: flex-start;
  }

  .visit-website:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  .section-with-link {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .banner-image {
    background-image: url('//src/assets/myka-banner-mb.jpg');
    width: 100%;
  }
}
