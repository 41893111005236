.banner-image {
  background-image: url('//src/assets/oal-banner-dt.jpg');
  width: 100%;
}

.section-with-link {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.wrapper-portfolio-images-oal {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  margin-inline: 30px;
  padding-bottom: 30px;
}

.wrapper-portfolio-image-inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  margin-inline: 0px;
  padding-bottom: 0px;
}

.visit-website:hover {
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 800px) {
  .section-with-link {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .wrapper-portfolio-images-oal {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    margin-inline: 20px;
    justify-items: center;
  }

  .wrapper-portfolio-image-inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    margin-inline: 0px;
    padding-bottom: 0px;
  }
}

.banner-image {
  background-image: url('//src/assets/oal-banner-mb.jpg');
  width: 100%;
}
